import React from "react";

import InfoImage from "Pages/Orders/ListPlansOrders/components/DetailsInfoImage";
import { HomeType, PatientType } from "Pages/Orders/Token/interface";

import { CopyToClipboard } from "Utils/Copy";
import { TextEllipsis } from "Utils/Uploads";

import { DetailsInfo } from "Components/DetailsInfo";

import { Patient, PatientItems, PatientsContainer } from "./styles";

interface AttendanceProps {
  payment?: number;
  home?: HomeType;
  patients: PatientType[];
  scheduledAttendance?: string;
  attendance_link?: string;
  vaccineCards?: any;
}

export function Attendance({
  home,
  patients,
  payment,
  scheduledAttendance,
  attendance_link,
  vaccineCards,
}: AttendanceProps) {
  const checkMimeType = (patient: PatientType) => {
    const foundFile = JSON.parse(vaccineCards[String(patient.patient_id)]);
    let urlString = "";

    switch (foundFile.mimetype) {
      case "application/pdf":
        urlString = `data:application/pdf;base64,${foundFile.image}`;
        break;
      case "image/png":
        urlString = `data:image/png;base64,${foundFile.image}`;
        break;
      default:
        urlString = `data:image/jpeg;base64,${foundFile.image}`;
    }

    return urlString;
  };

  return (
    <>
      <PatientsContainer>
        {patients && patients.length >= 1 ? (
          patients.map((patient, index) => (
            <Patient key={index}>
              <div className="mainSection">
                <DetailsInfo
                  label={`Paciente ${index + 1}: `}
                  content={patient.full_name || patient.name}
                />
                <DetailsInfo
                  label="Data de Nascimento: "
                  content={patient.birthdate}
                />
                {patient.cpf && (
                  <DetailsInfo label="CPF: " content={patient.cpf} />
                )}
                {patient.passport && (
                  <DetailsInfo
                    label="Passaporte: "
                    content={patient.passport}
                  />
                )}
                <DetailsInfo label="Telefone: " content={patient.telephone} />
                <DetailsInfo label="E-mail: " content={patient.email} />
                {!!patient.schedule_id && (
                  <DetailsInfo
                    label="ID: "
                    content={`Nº ${patient.schedule_id}`}
                  />
                )}

                {vaccineCards ? (
                  <>
                    <DetailsInfo label="Carteirinha de Vacinação:" content="" />
                    <div style={{ marginTop: "-1.2em" }}>
                      <InfoImage
                        url={checkMimeType(patient)}
                        name={
                          JSON.parse(vaccineCards[String(patient.patient_id)])
                            .filename
                        }
                        type={
                          JSON.parse(vaccineCards[String(patient.patient_id)])
                            .mimetype
                        }
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <PatientItems className="items">
                <dl>
                  <dt>Itens do Atendimento</dt>
                  {patient.exams.map((exam) => (
                    <dd key={exam.product_name}>
                      {TextEllipsis(exam.product_name, 30)}
                    </dd>
                  ))}
                </dl>
                {attendance_link && (
                  <dl className="consult-unit">
                    <dt>Link da Consulta</dt>

                    <button
                      onClick={() =>
                        CopyToClipboard({
                          text: encodeURI(attendance_link),
                          message: "Copiado !",
                        })
                      }
                      type="button"
                      className="button _xsmall"
                    >
                      Copiar link
                    </button>
                  </dl>
                )}
              </PatientItems>
            </Patient>
          ))
        ) : (
          <DetailsInfo
            label="Paciente"
            content="Não foi possível carregar os dados!"
          />
        )}

        {home ? (
          <Patient>
            <div>
              <DetailsInfo
                label="Data de coleta"
                content={scheduledAttendance}
              />
              <DetailsInfo
                label="Dia da Semana"
                content={home?.collect_address.week_day}
              />
              <DetailsInfo
                label="Taxa de coleta"
                content={home?.collect_address.home_collect_tax}
              />
              {payment && (
                <DetailsInfo
                  label="Identificador/Agendamento"
                  content={payment ?? ""}
                />
              )}
            </div>

            <PatientItems>
              <DetailsInfo
                label="CEP"
                content={home?.collect_address.zip_code}
              />
              <DetailsInfo
                label="Rua"
                content={home?.collect_address.address}
              />
              <DetailsInfo
                label="Bairro"
                content={home?.collect_address.neighborhood}
              />
              {home?.collect_address.complement && (
                <DetailsInfo
                  label="Complemento"
                  content={home?.collect_address.complement}
                />
              )}
              <DetailsInfo
                label="Número"
                content={home?.collect_address.number}
              />
              <DetailsInfo
                label="Cidade/Estado"
                content={`${home?.collect_address.city} / ${home?.collect_address.state}`}
              />
            </PatientItems>
          </Patient>
        ) : (
          <div />
        )}
      </PatientsContainer>
    </>
  );
}
