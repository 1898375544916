import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { format } from "date-fns";
import { Attendance } from "Pages/Person/TokenTabs/ViewOrder/Attendance";
import { ClinicalAttendance } from "Pages/Person/TokenTabs/ViewOrder/ClinicalAttendance";

import * as OrderRequests from "Requests/Orders";

import { hasPathInArray, validateArrayType } from "Utils/Arrays";
import { formatDateToBR } from "Utils/Date";
import { MaskCPF } from "Utils/Masks";
import { validateObjectType } from "Utils/Objects";
import { createQueryString } from "Utils/WEB";

import { Accordion } from "Components/Accordion";
import { DetailsInfo } from "Components/DetailsInfo";
import { CancelScheduleModal } from "Components/Modals/CancelScheduleModal";
import { RescheduleModal } from "Components/Modals/RescheduleModal";
import { RescheduleModalFinal } from "Components/Modals/RescheduleModalFinal";
import { RescheduleModalReturn } from "Components/Modals/RescheduleModalReturn";
import { ResendDocuments } from "Components/Modals/ResendDocuments";
import PageHeader from "Components/Pages/Header";
import PageWrapper from "Components/Pages/Wrapper";
import Breadcrumb from "Components/Shared/Breadcrumb";

import CalendarIcon from "Assets/Images/calendarWhite.png";
import EmailLogo from "Assets/Images/email.svg";
import EventIcon from "Assets/Images/event.png";
import loadingIcon from "Assets/Images/loading-purple.gif";
import TrashIcon from "Assets/Images/trash_closed.png";

import { GeneralDataType, UseParamsProps } from "./interface";
import {
  AttendancesContainer,
  ContentArticle,
  ContentContainer,
  Footer,
  GridDetailsItems,
  GridPaymentItems,
  LoadingAttendance,
} from "./styles";

function getBreadcrumb(location: any) {
  const originPersonId = location?.state?.originPersonId;

  return [
    {
      path: "/pedidos",
      label: "Pedidos",
      current: false,
    },
    {
      path: originPersonId ? "/pessoas" : "/pedidos",
      label: originPersonId ? "Todas as pessoas" : "Todos os pedidos",
      current: false,
    },
    {
      path: originPersonId ? `/ficha-cliente/${originPersonId}` : "#",
      label: originPersonId ? "Ficha do cliente" : "Ficha do pedido",
      current: false,
    },
    {
      path: "#",
      label: "Visualizar pedido",
      current: true,
    },
  ];
}

const OrderToken = () => {
  const params = useParams<UseParamsProps>();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isRescheduleModalOpenReturn, setIsRescheduleModalOpenReturn] =
    useState(false);
  const [isRescheduleModalOpenFinal, setIsRescheduleModalOpenReturnFinal] =
    useState(false);
  const [isModalResendDocumentsOpen, setIsModalResendDocumentsOpen] =
    useState(false);

  const [generalData, setGeneralData] = useState<GeneralDataType>({
    addition: "",
    attendance: {
      unit: {
        patients: [],
      },
    },
    budget_key: "",
    cart: [],
    channel_id: 0,
    commercial_document_number: "",
    compound_order: false,
    coupon_code: "",
    created_at: "",
    date: "",
    discounts: "",
    home_collect_date: "",
    home_collected: false,
    id: 0,
    id_source: 0,
    installments_number: 0,
    integration_requisiton_code: "",
    motion_integration_date: null,
    motion_integration_status: "",
    order_covenant: [],
    order_source: "",
    order_type: 0,
    origin_order_id: "",
    pacient: [],
    paid_value: "",
    payer: {
      id: 0,
      name: "",
      email: "",
      type: "",
      address: "",
      number: "",
      origin: 0,
      phone: "",
      birthdate: "",
    },
    paymentData: {
      card_banner_id: 1,
      coupon_code: "",
      cpf: "",
      credit_card_banner: "",
      form_payment: "",
      installments_number: 0,
      name: "",
      nsu_number: "",
      number: null,
      order_id: "",
      payment_link: "",
      payment_transaction_date: "",
      payment_type: "",
      street: null,
      voucher: "",
    },
    payment_discount: "",
    selected_contacts: {
      email: "",
      person_id: 0,
      phone: "",
    },
    status: "",
    sub_orders: [],
    subtotal: "",
    total: "",
    unit_id: 0,
    unit_name: "",
    units: [],
    home_collect_value: "",
    // vaccine_cards: []
  });
  const [paymentData, setPaymentData] = useState<any>([]);
  const [appointmentGender, setAppointmentGender] = useState<any>({});
  const [orderId, setOrderId] = useState<any>(null);
  const [open, setOpen] = useState<{ [key: string]: boolean }>({
    geral: true,
    payment: true,
    items: true,
  });

  const disableRescheduleButton = !hasPathInArray(
    ["Cancelado", "Agendado", "Finalizado", "finalizado", "Ncompareceu"],
    appointmentGender.status
  );
  const showWaitRoom =
    appointmentGender.status !== "Cancelado"
      ? appointmentGender?.wait_room_link
      : null;

  const orderIdSource =
    generalData?.order_source === "Colabi"
      ? generalData?.id
      : generalData?.id_source;

  function handleOpen(key: string) {
    setOpen((prevState) => ({
      ...prevState,
      [key]: Object.keys(open).includes(key) ? !prevState[key] : false,
    }));
  }

  function createToast(style: "error", message: string) {
    toast.dismiss();

    setTimeout(() => {
      toast[style](message);
    }, 400);
  }

  function getPatientId(data?: any) {
    const selectedData = data || generalData;

    if (Array.isArray(selectedData?.pacient)) {
      const patient = selectedData.pacient[0];

      return patient ? patient.id : "";
    }

    return selectedData?.pacient?.id;
  }

  async function handleFetch(
    id: string | number,
    appointment_id?: string,
    person_id?: string
  ) {
    try {
      const orderResponse = await OrderRequests.GetDetails(id);

      const dateOrderResponse = orderResponse.data.motion_integration_date
        ? new Date(orderResponse.data.motion_integration_date)
        : new Date();
      const dateMotion = orderResponse.data.motion_integration_date
        ? orderResponse.data.motion_integration_date.split(" ")
        : [];

      const isConsultation = orderResponse?.data.order_type === 5;

      if (orderResponse.success) {
        setGeneralData({
          appointment_at_date_integration: format(
            dateOrderResponse,
            "dd/MM/yyyy"
          ),

          ...orderResponse.data,
          motion_integration_date:
            dateMotion.length > 0
              ? `${formatDateToBR(dateMotion[0])} / ${dateMotion[1]}`
              : "",
        });
        setOrderId(orderResponse?.data.id);
        if (isConsultation) {
          await handleAppointmentGender(
            orderResponse.data,
            person_id || getPatientId(orderResponse.data)
          );
        }
      }
    } catch (error: any) {
      createToast("error", error?.message);
      history.push("/pedidos");
    }
  }

  async function handleAppointmentGender(data: any, person_id: string) {
    try {
      const orderSource = data?.order_source.toLowerCase();

      const appointmentGender = await OrderRequests.GetAppointmentGender(
        person_id,
        data?.order_source === "1" || orderSource === "web"
          ? data.id_source
          : data.id,
        data?.order_source === "1" || orderSource === "web" ? 1 : 2
      );
      const date = new Date(appointmentGender.data.appointment_at);
      if (appointmentGender.success) {
        setAppointmentGender({
          unit_name: data?.unit_name,
          appointment_at_date: format(date, "dd/MM/yyyy"),
          appointment_at_hour: format(date, "HH:mm:ss"),
          ...appointmentGender.data,
          ...([8, 9].includes(appointmentGender.data?.procedure) && {
            wait_room_link: `${
              process.env.REACT_APP_URL_CLINIC_SITE
            }/pre-atendimento?${createQueryString({
              id_scheduling: appointmentGender.data?.id_appointment,
              appointment_at: appointmentGender.data?.appointment_at,
              name: data?.pacient[0]?.name,
            })}`,
          }),
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handlePaymentData(id: string | number) {
    try {
      const paymentResponse = await OrderRequests.GetPaymentData(id);

      const { payment_transaction_date, number, street } =
        paymentResponse.data[0];
      const date = payment_transaction_date.split(" ");

      const paymentInfo = {
        ...paymentResponse.data[0],
        payment_transaction_date: `${formatDateToBR(date[0])} / ${date[1]}`,
        address: `${street},${number}`,
      };

      if (paymentResponse.success) {
        setPaymentData(paymentInfo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchRequests = async (appointment_id?: string | number) => {
    try {
      setLoading(true);

      if (params.id) {
        const promiseArray = [
          handleFetch(params.id, String(appointment_id)),
          handlePaymentData(params.id),
        ];

        await Promise.allSettled(promiseArray);
      }

      if (appointment_id) {
        handleAppointmentGender(generalData, getPatientId());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getConsultTitle = (status: string) => {
    let text = "";
    switch (status) {
      case "CONS-MED-AVU":
        text = "Consulta integrada";
        break;
      case "CONS-TELE-MED":
        text = "Consulta por telemedicina";
        break;
      case "RET-CTM":
        text = "Consulta integrada - retorno";
        break;
      case "RET-CMI":
        text = "Consulta por telemedicina - retorno!";
        break;
      default:
        break;
    }
    return text;
  };

  useEffect(() => {
    if (params.id && params.id !== null) {
      fetchRequests();
    }
  }, [params.id]);

  if (loading) {
    return (
      <LoadingAttendance>
        <p>Carregando dados do pedido</p>
        <img src={loadingIcon} />
      </LoadingAttendance>
    );
  }

  console.log(generalData);

  return (
    <PageWrapper className="entry-app-content">
      <div className="content-holder content-holder-no-filter remove-padding">
        <div className="page-heading">
          <div className="wrapper-header-content">
            <PageHeader title="Ficha do Pedido">
              <Breadcrumb list={getBreadcrumb(location)} />
            </PageHeader>
            <div className="actions-holder">
              <button
                onClick={() => {
                  history.goBack();
                }}
                type="button"
                className="link link-danger"
              >
                Voltar
              </button>
            </div>
          </div>
        </div>

        {validateObjectType(generalData) && (
          <div className="page-content">
            <ContentContainer>
              <ContentArticle open={open?.geral}>
                <Accordion
                  title="Dados Gerais"
                  open={open?.geral}
                  handleOpen={() => handleOpen("geral")}
                  notCenter
                >
                  <DetailsInfo
                    label="Nº do pedido:"
                    content={generalData?.id || "Não informado"}
                  />
                  <DetailsInfo
                    label="Nº do pedido de origem:"
                    content={generalData?.id_source || "Não informado"}
                  />
                  <DetailsInfo
                    label="Data do pedido:"
                    content={generalData?.date || "Não informado"}
                  />
                  <DetailsInfo
                    label="Data de integração Motion:"
                    content={
                      generalData?.motion_integration_date || "Não informado"
                    }
                  />
                  <DetailsInfo
                    label="Status:"
                    content={generalData?.status || "Não informado"}
                  />
                  <DetailsInfo
                    label="Origem:"
                    content={generalData?.order_source || "Não informado"}
                  />
                </Accordion>
              </ContentArticle>

              <ContentArticle open={open?.payment} className="payment-info">
                <Accordion
                  title="Dados de Pagamento"
                  open={open?.payment}
                  handleOpen={() => handleOpen("payment")}
                  notCenter
                >
                  <GridPaymentItems className="payment-grid">
                    <DetailsInfo
                      label="Data de pagamento:"
                      content={
                        paymentData?.payment_transaction_date || "Não informado"
                      }
                    />

                    <DetailsInfo
                      label="Número de parcelas:"
                      content={
                        paymentData?.installments_number || "Não informado"
                      }
                    />

                    <DetailsInfo
                      label="Tipo de pagamento:"
                      content={paymentData?.form_payment || "Não informado"}
                    />

                    <DetailsInfo
                      label="Forma de pagamento:"
                      content={paymentData?.form_payment || "Não informado"}
                    />
                    <DetailsInfo
                      label="NSU:"
                      content={paymentData?.nsu_number || "Não informado"}
                    />
                    <DetailsInfo
                      label="Bandeira do cartão:"
                      content={
                        paymentData?.credit_card_banner || "Não informado"
                      }
                    />
                    <DetailsInfo
                      label="Cupom de desconto:"
                      content={paymentData?.coupon_code || "Não informado"}
                    />

                    <DetailsInfo
                      label="Nome"
                      content={paymentData?.name || "Não informado"}
                    />

                    <DetailsInfo
                      label="Documento:"
                      content={MaskCPF(paymentData?.cpf) || "Não informado"}
                    />

                    <DetailsInfo
                      label="Endereço:"
                      content={
                        paymentData?.address &&
                        paymentData.address !== "null,null"
                          ? paymentData.address
                          : "Não informado"
                      }
                    />
                  </GridPaymentItems>
                </Accordion>
              </ContentArticle>

              <ContentArticle
                open={open?.items}
                twoColumns
                className="itens-info"
              >
                <Accordion
                  title="Itens do Pedido"
                  open={open?.items}
                  handleOpen={() => handleOpen("items")}
                  notCenter
                >
                  <GridDetailsItems>
                    <dt>Nome</dt>
                    <dt>Quantidade</dt>
                  </GridDetailsItems>

                  {generalData?.cart?.map((item: any, index: number) => (
                    <Fragment key={index}>
                      <GridDetailsItems>
                        <dd>{item.product.product}</dd>
                        <dd>{item.amount}</dd>
                      </GridDetailsItems>
                    </Fragment>
                  ))}
                </Accordion>
              </ContentArticle>
            </ContentContainer>
            <AttendancesContainer>
              {["CONS-MED-AVU", "CONS-TELE-MED", "RET-CTM", "RET-CMI"].includes(
                generalData?.cart[0]?.product.sku
              ) ? (
                <ContentArticle open={open?.unitAttendance ?? true}>
                  <Accordion
                    title={`Atendimento 1 -  ${getConsultTitle(
                      generalData?.cart[0]?.product.sku
                    )} `}
                    open={open?.unitAttendance ?? true}
                    handleOpen={() => handleOpen("unitAttendance")}
                    notCenter
                  >
                    <ClinicalAttendance
                      patients={generalData?.attendance?.unit?.patients ?? []}
                      appointmentGender={appointmentGender}
                      unit_name={
                        generalData?.attendance?.unit?.patients[0]?.exams[0]
                          ?.unit_name ?? generalData?.unit_name
                      }
                      waitRoomLink={
                        appointmentGender.status !== "Cancelado" &&
                        appointmentGender.wait_room_link
                      }
                      isTelemedicine={
                        generalData?.cart[0]?.product.sku === "CONS-TELE-MED"
                      }
                    />
                  </Accordion>
                </ContentArticle>
              ) : (
                <>
                  {generalData?.attendance?.home && (
                    <ContentArticle open={open?.homeAttendance ?? true}>
                      <Accordion
                        title="Atendimento 1 - Domiciliar"
                        open={open?.homeAttendance ?? true}
                        handleOpen={() => handleOpen("homeAttendance")}
                      >
                        <Attendance
                          patients={generalData?.attendance?.home?.patients}
                          home={generalData?.attendance?.home}
                          payment={generalData?.payer?.origin}
                          scheduledAttendance={
                            generalData.home_collect_date
                              ? generalData?.home_collect_date.replaceAll(
                                  "/",
                                  "-"
                                )
                              : ""
                          }
                          vaccineCards={generalData.vaccine_cards}
                        />
                      </Accordion>
                    </ContentArticle>
                  )}
                  {generalData?.attendance.unit && (
                    <ContentArticle open={open?.unitAttendance ?? true}>
                      <Accordion
                        title={`Atendimento ${
                          generalData?.attendance.home ? 2 : 1
                        } - Unidade`}
                        open={open?.unitAttendance ?? true}
                        handleOpen={() => handleOpen("unitAttendance")}
                      >
                        <Attendance
                          patients={
                            generalData?.attendance?.unit?.patients ?? []
                          }
                          vaccineCards={generalData.vaccine_cards}
                          attendance_link={appointmentGender.wait_room_link}
                        />
                      </Accordion>
                    </ContentArticle>
                  )}
                </>
              )}
            </AttendancesContainer>
          </div>
        )}
      </div>

      <Footer>
        {generalData?.order_type === 5 && appointmentGender && (
          <div>
            {appointmentGender.return_released ? (
              <div className="wrapper-consult-buttons-return">
                <button
                  className="document-button"
                  onClick={() => setIsRescheduleModalOpenReturn(true)}
                >
                  AGENDAR RETORNO
                  <img src={CalendarIcon} width={18} />{" "}
                </button>
              </div>
            ) : (
              <>
                <button
                  className="document-button"
                  disabled={!validateArrayType(appointmentGender.documents)}
                  onClick={() => setIsModalResendDocumentsOpen(true)}
                >
                  REENVIAR DOCUMENTOS
                  <img src={EmailLogo} width={13.5} />{" "}
                </button>
                <div className="wrapper-consult-buttons">
                  <button
                    disabled={appointmentGender.status !== "Agendado"}
                    className="button-danger"
                    onClick={() => setIsModalOpen(true)}
                  >
                    CANCELAR CONSULTA
                    <img src={TrashIcon} width={13.5} />{" "}
                  </button>

                  {appointmentGender.procedure_name ===
                  "Retorno - Consulta Médica Integrada" ? (
                    <button
                      disabled={disableRescheduleButton}
                      className="button"
                      onClick={() => setIsRescheduleModalOpenReturnFinal(true)}
                    >
                      REAGENDAR CONSULTA
                      <img src={EventIcon} width={18} />{" "}
                    </button>
                  ) : (
                    <button
                      disabled={disableRescheduleButton}
                      className="button"
                      onClick={() => setIsRescheduleModalOpen(true)}
                    >
                      REAGENDAR CONSULTA
                      <img src={EventIcon} width={18} />{" "}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </Footer>
      <CancelScheduleModal
        modalVisible={isModalOpen}
        appointment_id={appointmentGender.id_appointment}
        modalDismiss={() => setIsModalOpen(false)}
        personData={generalData?.pacient[0]}
        loading={loading}
        refreshData={fetchRequests}
        person_id={getPatientId()}
        order_id={String(orderIdSource)}
      />

      <ResendDocuments
        modalVisible={isModalResendDocumentsOpen}
        modalDismiss={() => setIsModalResendDocumentsOpen(false)}
        documents={appointmentGender.documents}
      />

      <RescheduleModal
        modalVisible={isRescheduleModalOpen}
        modalDismiss={() => setIsRescheduleModalOpen(false)}
        loading={loading}
        personData={generalData?.pacient[0]}
        appointment_id={appointmentGender.id_appointment}
        person_id={getPatientId()}
        professional_code={appointmentGender.professional_code}
        refreshData={fetchRequests}
        procedure={appointmentGender.procedure}
        isSiteOrder={
          generalData?.order_source === "Web" ||
          generalData?.order_source === "1"
        }
        siteId={generalData?.id_source}
        return_released={appointmentGender.return_released}
        product_code={generalData?.cart[0]?.product.sku}
      />

      <RescheduleModalFinal
        modalVisible={isRescheduleModalOpenFinal}
        modalDismiss={() => setIsRescheduleModalOpenReturnFinal(false)}
        loading={loading}
        personData={generalData?.pacient[0]}
        appointment_id={generalData?.appointment_id}
        person_id={getPatientId()}
        professional_code={appointmentGender.professional_code}
        refreshData={fetchRequests}
        procedure={appointmentGender.procedure}
        return_released={appointmentGender.return_released}
        product_code={generalData?.cart[0]?.product.sku}
      />

      <RescheduleModalReturn
        modalVisible={isRescheduleModalOpenReturn}
        modalDismiss={() => setIsRescheduleModalOpenReturn(false)}
        loading={loading}
        personData={generalData?.pacient[0]}
        appointment_id={appointmentGender.id_appointment}
        professional_code={appointmentGender.professional_code}
        order_id={orderId}
        person_id={getPatientId()}
        refreshData={fetchRequests}
        procedure={appointmentGender.procedure === 5 ? 6 : 8}
        return_released={appointmentGender.return_released}
        product_code={generalData?.cart[0]?.product.sku}
      />
    </PageWrapper>
  );
};

export default OrderToken;
