import React, { useMemo, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import moment from "moment";

import { Reschedule } from "Requests/ClinicalSchedule";

import { hasPathInArray } from "Utils/Arrays";
import { Loading } from "Utils/Loading";
import { FakePromise } from "Utils/Promise";
import { createToast } from "Utils/toastFunc";

import { ClinicalSchedulesWeek } from "Components/Schedule/ClinicalScheduleWeeks";

import AcceptIconColorful from "Assets/Images/acceptIconColorful.svg";
import LoadingPurple from "Assets/Images/loading-purple.gif";

import { RescheduleModalProps } from "./interface";
import { Header } from "./styles";

export function RescheduleModal({
  modalVisible,
  modalDismiss,
  loading,
  personData,
  appointment_id,
  person_id,
  professional_code,
  refreshData,
  procedure,
  return_released,
  isSiteOrder,
  siteId,
  product_code,
}: RescheduleModalProps) {
  const router = useHistory();
  const user_logged = useSelector((state: any) => state.User.userData);

  const [cancelled, setCancelled] = useState(false);
  const [loadingReschedule, setLoadingReschedule] = useState(false);
  const [selectedTime, setSelectedTime] = useState<any>();
  const [id, setId] = useState<string | null>(null);

  const isTelemedicine = useMemo(
    () => ["CONS-TELE-MED", "RET-CTM"].includes(product_code ?? ""),
    [product_code]
  );

  console.log(isTelemedicine);

  function validateError(errorResponse: string) {
    if (errorResponse) {
      const messages = [
        "Time is no longer available.",
        "Appointment isn't valid anymore",
      ];

      return hasPathInArray(messages, errorResponse);
    }
  }

  async function reschedule() {
    setLoadingReschedule(true);
    const day = personData.birthdate.substring(0, 2);
    const month = personData.birthdate.substring(3, 5);
    const year = personData.birthdate.substring(6, 10);

    const formattedDate = `${year}-${month}-${day}`;
    const formattedPhone = personData.phone.replace(/[^\d]+/g, "");

    const channel_id = 2;
    const order_id = isSiteOrder ? siteId : personData.order_id;

    try {
      const payload = {
        appointment_id,
        person_id,
        hour: id,
        document: personData.document,
        name: personData.name,
        birthdate: formattedDate,
        biological_sex: personData.gender,
        professional_code,
        phone: formattedPhone,
        email: personData.email,
        order_id,
        zip_code: "",
        country: "",
        city: "",
        neighborhood: "",
        street: "",
        number: "",
        complement: "",
        state: "",
        user_id: user_logged.id,
        channel_id,
        procedure,
      };
      const response = await Reschedule(payload, appointment_id);

      setCancelled(true);

      await FakePromise(4000);
      modalDismiss();

      refreshData(response.data.id);
    } catch (err: any) {
      const {
        data: { errors },
      } = err.response;

      const errorValidated = validateError(errors[0].message);

      if (errorValidated) {
        createToast(
          "error",
          "Este horário não está mais disponível. Escolha um novo horário."
        );

        return;
      }

      createToast("error", "Esse agendamento não pode ser alterado.");
      modalDismiss();
    } finally {
      setLoadingReschedule(false);
    }
  }

  const formatDate = (type?: string) => {
    if (selectedTime) {
      const newDate = selectedTime.split(" ");

      if (type === "date") {
        return moment(newDate[0]).format("DD/MM");
      }

      const hour = newDate[1].split(":");
      return `${hour[0]}h${hour[1]}`;
    }
  };

  const handleModalDismiss = () => {
    modalDismiss();
    return router.push("/pedidos");
  };

  return (
    <Modal
      isOpen={!!modalVisible}
      bodyOpenClassName="modal-content-open"
      className="modal-content modal-fixed-header modal-reschedule-header "
      overlayClassName={{
        base: "modal",
        afterOpen: "after-open",
        beforeClose: "before-close",
      }}
      closeTimeoutMS={500}
      onRequestClose={modalDismiss}
    >
      {loading ? (
        <div style={{ marginTop: "1rem" }}>
          <Loading classCss="loading-centered" sizeImg={undefined} />
        </div>
      ) : (
        <>
          <Header>
            {cancelled ? (
              <div className="headerCancelled">
                <img src={AcceptIconColorful} alt="icone" />
                <strong>Consulta reagendada</strong>
                <span>com sucesso!</span>
              </div>
            ) : (
              <h3>Reagendar Consulta</h3>
            )}

            {cancelled ? (
              <p className="subtitle-modal">
                A consulta foi a reagendada para o dia {formatDate("date")} às{" "}
                {formatDate()}. <br />O cliente irá receber um e-mail com a nova
                data.{" "}
              </p>
            ) : (
              <p className="subtitle-modal">Selecione uma data disponível</p>
            )}

            {!cancelled && (
              <ClinicalSchedulesWeek
                code={isTelemedicine ? "tele-medicine" : professional_code}
                setId={setId}
                numberOfDays={3}
                previousWeekText="Anterior"
                nextWeekText="Seguinte"
                procedure={procedure}
                return_released={return_released}
                selectedSchedule={setSelectedTime}
              />
            )}

            {cancelled ? (
              <div className="buttons">
                <button className="button" onClick={() => handleModalDismiss()}>
                  Finalizar atendimento
                </button>
              </div>
            ) : (
              <div className="buttons">
                <button
                  disabled={loadingReschedule}
                  className="button danger"
                  onClick={modalDismiss}
                >
                  Voltar
                </button>
                <button
                  className={`button ${
                    loadingReschedule ? "button-loading _white" : ""
                  }`}
                  disabled={!id || loadingReschedule}
                  onClick={reschedule}
                >
                  {loadingReschedule ? (
                    <img src={LoadingPurple} alt="" className="gif" />
                  ) : (
                    "confirmar agendamento"
                  )}
                </button>
              </div>
            )}
          </Header>
        </>
      )}
    </Modal>
  );
}
